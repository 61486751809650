import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/service/auth.service';
import $ from 'jquery';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

 
  model: any = {};

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  submitForm() {
    

    let payload = { "yourName": (this.model.yourName), "emailAddress": this.model.emailAddress, "webSite": this.model.webSite, "message": this.model.message }
    this.authService.getInTouch(payload)
      .subscribe( (data: { result: {} }) => {
        $('#formUpdateMessage').html('<div class="alert alert-success show" role="alert">' + data.result + '</div>');
        $('#formUpdateMessage').show();
        setTimeout(function () {
          $('#formUpdateMessage').fadeOut('fast');
          $('#formUpdateMessage').html('');
        }, 8000); // <-- time in milliseconds
        console.log("POST Request is successful ", data);
        },
        error  => {
        console.log("Error", error);
        });

  }

}