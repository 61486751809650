import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Subscription, Observable } from 'rxjs';
import $ from 'jquery';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  model: any = {}; 
  showUserID: boolean = true;
  showNext: boolean = true;
  showQues: boolean = false;
  showPaswrd: boolean = false;
  showCheck: boolean = false;
  createSubsribe: Subscription;
  
    constructor(private authService: AuthService) { }

  ngOnInit() {
  }
  onClickNext() {

    let payload = { "userID": (this.model.userID) }
   this.createSubsribe = this.authService.secQuestion(payload)
      .subscribe((data: { result: {} }) => {
        console.log("POST Request is successful ", data);
        let text: string =(typeof data.result == "string" ? data.result :"");
        this.model.secQuestion = data.result ;
        this.showQues = true;
        this.showNext = false;
        this.showCheck = true;
      },
        error => {
          console.log("Error", error);
        });

  }
  onClickCheck() {

    let payload = { "userID": (this.model.userID), "security_ans": (this.model.security_ans) }
    this.authService.secAnsValidation(payload)
      .subscribe(data => {
        console.log("POST Request is successful ", data);
        this.showPaswrd = true;
        this.showQues = true;
        this.showNext = false;
        this.showUserID = true;
        this.showCheck = false;
      },
        error => {
          console.log("Error", error);
        });

  }
  onClickSubmit() {

    let payload = { "userID": (this.model.userID), "security_ans": (this.model.security_ans), "password": (this.model.password) }
    this.authService.updatePassword(payload)
      .subscribe(data  => {
        $('#formUpdateMessage').html('<div class="alert alert-success show" role="alert">' + "Password is updated for User: " +this.model.userID + '</div>');
        $('#formUpdateMessage').show();
        this.showPaswrd = false;
        this.showQues = false;
        this.showUserID = false;
        this.showNext = false;
      },
        error => {
          console.log("Error", error);
        });
  }
}
