// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  "auth_token": "Basic c3VwZXJ1c2VyOnN1cGVydXNlcg==",
  "sap_list_url": "https://genpactmanzeeragyd.com/domo/getServicetypes/",
  "login_url": "https://genpactmanzeeragyd.com/domo/login/userlogin",
  "service_list_url": "https://genpactmanzeeragyd.com/domo/readConfig/",
  "version_list_url": "https://genpactmanzeeragyd.com/domo/getVersions/",
  "api_list_url": "https://genpactmanzeeragyd.com/domo/getAPIs/",
  "api_field_url": "https://genpactmanzeeragyd.com/domo/getAPIFields/",
  "config_log_url": "https://genpactmanzeeragyd.com/domo/readUserLogs/",
  "disable_config_url": "https://genpactmanzeeragyd.com/domo/disableConfig/",
  "delete_config_url": "https://genpactmanzeeragyd.com/domo/deleteConfig/",
  "create_config_url":"https://genpactmanzeeragyd.com/domo/create_config/",
  "update_config_url": "https://genpactmanzeeragyd.com/domo/updateConfig/",
  "read_schedule_url": "https://genpactmanzeeragyd.com/domo/readSchedule/",
  "change_mode_config_url": "https://genpactmanzeeragyd.com/domo/changeMode/",
  "run_now_config_url2": "https://genpactmanzeeragyd.com/domo/domo_upload/",
  "run_now_config_url": "https://genpactmanzeeragyd.com/domo/executeTarget/",
  "targets_config_url": "https://genpactmanzeeragyd.com/domo/getTargets/",
  "target_fields_config_url": "https://genpactmanzeeragyd.com/domo/getTargetFields/",
  "in_touch_config_url":"https://genpactmanzeeragyd.com/domo/getInTouch/",
  "security_question_url":"https://genpactmanzeeragyd.com/domo/secQuestion/",
  "sec_Ans_Validation_url":"https://genpactmanzeeragyd.com/domo/secAnsValidation/",
  "update_Password_url":"https://genpactmanzeeragyd.com/domo/updatePassword/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
